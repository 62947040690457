import React from 'react';
import Icon from "../../../../../common/components/atoms/Icon/Icon";
import BoostCard from "../../../../../common/components/organisms/BoostCard/BoostCard";


const BoostJoinTelegram = ({
    className,
    ...props
}) => {
    const componentName = 'boost-telegram';

    //  dummy some data
    const cardData = [
        {
            "cost": "0",
            "improvement_key": null,
            "improvement_value": null,
            "locked": !(props.upgrades?.['uu3'] ?? false) || props.upgrades?.['ub3'],
            "tag_key": "mega",
            "tag_value": "$$$",
            "type": "$5000 Bonus",
            "label": props.upgrades?.['ub3'] ? "Claimed" : "Get",
        }
    ]

    return (
        <section
            className={
                `boosts-container
                ${componentName}
                ${className}`
            }>
            <div className="header">
                <p>
                    <Icon name="telegram" />
                    {((!props.upgrades?.['uu3'] ?? true) && (
                        <b>Join us on Telegram</b>
                    )) || <b>Telegram Boosts</b>}
                </p>

                <button
                    className="btn small"
                    onMouseDown={() => {
                        window.open("https://t.me/SlingShotDAOGC", '_blank').focus();
                        props?.buyUpgrade('uu3');
                    }}
                    disabled={props.upgrades?.['uu3'] ?? false}
                >
                    Join
                </button>
            </div>

            {cardData.map((item, index) => (
                <BoostCard
                    key={index}
                    cardData={item}
                    clicked={(d) => {
                        props?.buyUpgrade('ub3');
                    }}
                />
            ))}
        </section>
    );
};

export default BoostJoinTelegram;

